import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/_lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        primary: 'primary-button-gradient text-primary-foreground',
        destructive:
          'border-transparent bg-danger text-danger-foreground hover:bg-danger/80',
        ghost: 'border-transparent bg-none hover:bg-muted/80',
        outline: 'text-foreground',
        success: 'bg-success/80 border-success-foreground text-success-foreground',
        warning: 'bg-warning/80 border-warning-foreground text-warning-foreground',
      },
      size: {
        default: 'text-xs',
        sm: 'text-xs',
        md: 'text-sm',
        lg: 'text-base'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
